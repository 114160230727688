import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getAPI, postAPI } from 'lib/APIHelpers';
import { useNavigateKeepParams } from 'hooks';

const filterQuestions = (updated_questions, questions_on_branch) => {
  if (questions_on_branch.length === 0) {
    return updated_questions;
  }

  return updated_questions.filter(({ question_id }) => questions_on_branch.includes(question_id));
};

const removeUnansweredQuestions = (updated_questions) => (
  updated_questions.filter(({ has_response }) => has_response)
);

const findQuestionIndex = (questions, questionId) => {
  return questions.findIndex(({ question_id: id }) => id === questionId);
};

const shouldAppendNextQuestion = (questions, currentQuestionId, nextQuestion) => {
  const currentQuestionIndex = findQuestionIndex(questions, currentQuestionId);
  const nextQuestionIndex = findQuestionIndex(questions, nextQuestion.question_id);
  const nextQuestionIsNew = nextQuestionIndex === -1;
  return currentQuestionIndex === questions.length - 1 && nextQuestionIsNew;
};

const updateQuestionInList = ({
  question_id,
  response_value,
  comment,
  questions
}) => {
  const index = questions.findIndex(({ question_id: id }) => id === question_id);
  if (index > -1) {
    const old_question = questions[index];
    questions[index] = {
      ...old_question,
      response_value,
      has_response: true,
      comment
    };
  }
  return [...questions];
};

const processBranching = (updated_questions, branching) => {
  const {
    questions_on_branch
  } = branching;

  updated_questions = filterQuestions(updated_questions, questions_on_branch);
  updated_questions = removeUnansweredQuestions(updated_questions);

  return updated_questions;
};

const ANSWER_QUESTION = ({
  question_id,
  percentage_complete,
  response_value,
  comment,
  next_question,
  branching
}) => (queryClientState) => {
  const {
    questions,
    ...rest
  } = queryClientState;

  let updated_questions = [...updateQuestionInList({
    question_id,
    percentage_complete,
    response_value,
    comment,
    questions,
  })];

  if (branching) {
    updated_questions = processBranching(updated_questions, branching);
  }

  if (!next_question) {
    return {
      ...rest,
      questions: updated_questions,
      percentage_complete: percentage_complete,
      hasFinishedSurvey: true
    };
  }

  if (next_question && shouldAppendNextQuestion(updated_questions, question_id, next_question)) {
    updated_questions = [...updated_questions, next_question];
  }

  // API update has returned a next_question, so any 'last_question' state is old so we reset it to false.
  // Unset any 'error' in state as this API call was successful.
  return {
    ...rest,
    questions: updated_questions,
    percentage_complete: Math.round(percentage_complete),
    error: undefined,
    last_question: false
  };
};

const SCROLL_TO_NEXT_QUESTION = (question_id) => {
  const nextQuestionElement = document.getElementById(`question-${question_id}`)?.nextElementSibling;
  nextQuestionElement?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  nextQuestionElement?.focus({ preventScroll: true });
};

const ON_ANSWER_SUCCESS = (queryData) => (apiResponse, questionData) => {
  // Current data within useQuery.
  // - we use the last_question value to help determine when we should navigate to the submit page.
  const { queryKey, queryClient, navigateKeepParams, last_question } = queryData;
  // API data from successful answer reqquest.
  // - The 'next_question' value is also used to help determine when we should navigate to the submit page.
  //   If there is a 'next_question' returned we do not want to navigate to the submit page.
  const { next_question, percentage_complete, branching, error } = apiResponse;
  // Data from the question form that was submitted in the API request.
  // - We use this to update the question state in the UI.
  const { question_id, response_value, comment, is_auto_save } = questionData;

  const noNextQuestion = !next_question;
  const firstTimeComplete = noNextQuestion && !last_question;
  const lastQuestionUpdated = last_question
    && last_question.question_id === question_id
    && is_auto_save != true;

  const endOfSurvey = branching && branching.end_of_survey;

  const finishedEditing = lastQuestionUpdated;
  const canNavigateToSubmit = firstTimeComplete || finishedEditing || endOfSurvey;

  if (error) {
    queryClient.setQueryData(queryKey, (old_state) => ({
      ...old_state,
      error
    }));
  } else {
    queryClient.setQueryData(
      queryKey,
      ANSWER_QUESTION({
        question_id,
        percentage_complete,
        response_value,
        comment,
        next_question,
        branching
      })
    );

    if (noNextQuestion && canNavigateToSubmit) {
      navigateKeepParams('../submit');
    } else if (!is_auto_save) {
      SCROLL_TO_NEXT_QUESTION(question_id);
    }
  }
  return next_question;
};

const QUESTIONS_QUERY_NAME = 'getQuestionsData';

const SEND_ANSWER = ({ feedbackID, queryClient, queryKey }) => (
  async (response) => {
    queryClient.setQueryData(queryKey, (old_state) => ({
      ...old_state,
      error: undefined
    }));
    return postAPI(
      `api/v2/feedback/${feedbackID}/answer`,
      response
    );
  }
);

const ON_SUBMIT_SUCCESS = (
  { feedbackID, navigateKeepParams }
) => () => navigateKeepParams(`/${feedbackID}/thank_you`);

const ON_ERROR = (
  { queryKey, queryClient }
) => (
  { response }
) => {
  const {
    data
  } = response || {};

  const {
    error
  } = data || {};

  queryClient.setQueryData(
    queryKey, (old_state) => ({
      ...old_state,
      error
    })
  );
};

export const useQuestionsApi = ({
  feedbackID
}) => {
  const queryClient = useQueryClient();
  const navigateKeepParams = useNavigateKeepParams();
  const queryKey = [QUESTIONS_QUERY_NAME, feedbackID];

  const { isPending, data, isFetching } = useQuery({
    queryKey,
    queryFn: async () => {
      const {
        questions,
        percentage_complete,
        next_question,
        last_question,
        error
      } = await getAPI(`api/v2/feedback/${feedbackID}/answered_questions`);

      if (next_question) {
        questions.push(next_question);
      }

      return {
        questions: [...questions],
        percentage_complete: Math.round(percentage_complete),
        error,
        hasFinishedSurvey: !next_question,
        last_question
      };
    },
    throwOnError: true
  });

  const {
    mutate: onAnswer,
    isPending: answerLoading
  } = useMutation({
    mutationFn: SEND_ANSWER({ feedbackID, queryClient, queryKey }),
    onSuccess: ON_ANSWER_SUCCESS({ queryClient, queryKey, navigateKeepParams, last_question: data?.last_question }),
    onError: ON_ERROR({ queryClient, queryKey })
  });

  const {
    mutate: onSubmit,
    isPending: submitLoading
  } = useMutation({
    mutationFn: () => postAPI(
      `api/v2/feedback/${feedbackID}/complete_survey`
    ),
    onSuccess: ON_SUBMIT_SUCCESS({ feedbackID, navigateKeepParams }),
    onError: ON_ERROR({ queryClient, queryKey })
  });

  return {
    ...data,
    loading: isPending || isFetching,
    answerLoading,
    submitLoading,
    onAnswer,
    onSubmit
  };
};
